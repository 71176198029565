<template>
    <div>
        <div class="bg life">
            <div class="content-wrapper">
                <div class="title">生活</div>
                <div class="content">每一個人都會對生活抱著一份堅持</div>
            </div>
        </div>
        <div class="bg dream">
            <div class="content-wrapper">
                <div class="title">夢想居室</div>
                <div class="content">不會被單位大小所限，過程如何艱辛都會尋找</div>
            </div>
        </div>
        <div class="bg communicate">
            <div class="content-wrapper">
                <div class="title">溝通</div>
                <div class="content">實現夢想居室，需與客人耐心溝通；我們待客如朋友，提供最貼心、合適的意見</div>
            </div>
        </div>
        <div class="bg belief">
            <div class="content-wrapper">
                <div class="title">信念</div>
                <div class="content">
                    客人喜愛型家設計，更將這份喜愛分享給身邊的親友，令我們可以更加的堅持公司理念：
                    實事求是、有責任、有承擔
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "About",
        data() {
            return {};
        }
    };
</script>

<style>
</style>